<template>
    <div>
     
      <v-alert
        outlined
        v-if="countCC >= 1"
        prominent
        icon="mdi-bell-ring"
        type="error"
        border="left"
      >
        <v-row>
          <v-col class="grow">
            <h2 class="blue--text">
              <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
            </h2>
            <h3>
              แจ้งยกเลิก ประเมินคะแนน จำนวน
              {{ countCC }} ราย
            </h3>
          </v-col>
          <v-col class="shrink">
            <v-btn
              to="/admin/assistant_teacher_detail"
              color="dark"
              outlined
            >
              <v-icon>mdi-bell-ring</v-icon> ดำเนินการ</v-btn
            >
          </v-col>
        </v-row>
      </v-alert>
    </div>
  </template>
  
  <script>
  export default {
    name: "HRvecAlertAssistantTeacher",
  
    data() {
      return {
        ApiKey: "HRvec2021",
        assistant_teacherscc: [],
        countCC: ""
      };
    },
  
    async mounted() {
      await this.pertem_assis_teachASCCQueryAll();
    },
  
    methods: {
      async pertem_assis_teachASCCQueryAll() {
        let result = await this.$http.post("assistant_teacher.php", {
          ApiKey: this.ApiKey,
          assistant_teacher_lowscore: "Cancel"
        });
        this.assistant_teacherscc = result.data;
        if(this.assistant_teacherscc){        
        let data = this.assistant_teacherscc;
        let counts = 0;
        data.forEach(value => {
        if(value.assistant_teacher_comment=='cancel'){
         counts += 1;   
        }          
        });
        this.countCC = counts;
      }
      }
    }
  };
  </script>
  